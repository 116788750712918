export interface DashboardFeature {
  key: DashboardFeatureKey;
  title: React.ReactNode;
  description: string;
  altText: string;
}

export type DashboardFeatureKey =
  | 'programs'
  | 'brands'
  | 'webhooks'
  | 'transactions'
  | 'playground';

const stDashboardFeatures: DashboardFeature[] = [
  {
    key: 'brands',
    title: 'Brand management',
    description:
      'Easily add or remove brands from your program with just the brand’s name and address.',
    altText:
      'Easily add or remove brands from your program with just the brand’s name and address.',
  },
  {
    key: 'webhooks',
    title: 'Webhook notifications',
    description:
      'Create and manage webhooks to receive real-time payment events.',
    altText: 'Create and manage webhooks to receive real-time payment events.',
  },
  {
    key: 'transactions',
    title: 'Data processing',
    description:
      'Get cleaned, enriched data across all three payment card networks almost instantly.',
    altText:
      'Get cleaned, enriched data across all three payment card networks almost instantly.',
  },
  {
    key: 'playground',
    title: 'Developer Sandbox',
    description:
      'Quickly test brands, cards, transactions and webhooks in a sandbox environment.',
    altText:
      'Quickly test brands, cards, transactions and webhooks in a sandbox environment.',
  },
];

const txDashboardFeatures: DashboardFeature[] = [
  {
    key: 'webhooks',
    title: 'Webhook notifications',
    description:
      'Create and manage webhooks to receive notifications the moment an event occurs.',
    altText:
      'Create and manage webhooks to receive notifications the moment an event occurs.',
  },
  {
    key: 'transactions',
    title: 'Data processing',
    description: 'Get clean, standardized card data almost instantly.',
    altText: 'Get clean, standardized card data almost instantly.',
  },
  {
    key: 'playground',
    title: 'Developer Sandbox',
    description: 'Quickly test cards, transactions and webhooks.',
    altText: 'Quickly test cards, transactions and webhooks.',
  },
  {
    key: 'programs',
    title: 'Program management',
    description:
      'Group customers in the dashboard. Build custom logic on top of groups.',
    altText:
      'Group customers in the dashboard. Build custom logic on top of groups.',
  },
];

export { stDashboardFeatures, txDashboardFeatures };
