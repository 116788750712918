import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {
  Block,
  FeatureCTA,
  Flex,
  Heading,
  Section,
} from '../../../../components/styled';

import {
  DashboardFeature,
  DashboardFeatureKey,
  stDashboardFeatures,
  txDashboardFeatures,
} from './features/dashboard-features';
import {
  DashboardFeatureHeader,
  DashboardFeatureItem,
  DashboardFeaturesContainer,
  DashboardFeaturesContainerMobile,
  DashboardFeatureScreenshot,
} from '../styled';
import ArrowIcon from '../../../../static/icons/arrow.svg';
import { BenefitText, BenefitTitle } from '../../../index/styled';
import { colours } from '../../../../components/styled/variables';
import { ProgramType } from '../../types';

type DashboardFeaturesProps = {
  title: string;
  subtitle: string;
  programType: ProgramType;
};

export default function DashboardFeatures({
  title,
  subtitle,
  programType,
}: DashboardFeaturesProps) {
  const { selectTransactionAssets, transactionStreamAssets } =
    useStaticQuery(assetsQuery);

  const assets =
    programType === 'select-transactions'
      ? selectTransactionAssets.edges.map(e => e.node)
      : transactionStreamAssets.edges.map(e => e.node);

  const features =
    programType === 'select-transactions'
      ? stDashboardFeatures
      : txDashboardFeatures;

  const [selectedFeature, setSelectedFeature] = useState<DashboardFeatureKey>(
    features[0].key,
  );

  const id =
    programType === 'select-transactions'
      ? 'dashboardSignUp-productSelect-dashboardSection'
      : 'dashboardSignUp-productStream-dashboardSection';

  return (
    <Section bgColor={colours.lightGrey2} centered maxWidth={1248}>
      <Flex flow="row wrap" justifyContent="center">
        <DashboardFeatureHeader>
          <Heading as="h1" size={48}>
            {title}
          </Heading>
          <span>{subtitle}</span>
        </DashboardFeatureHeader>
        <Block>
          <DashboardFeaturesContainer>
            <ReactCSSTransitionGroup
              transitionName="transitiongroup"
              transitionEnterTimeout={400}
              transitionLeave={false}
            >
              <DashboardFeatureScreenshot
                key={selectedFeature}
                image={
                  assets.find(file => file.name === selectedFeature)
                    .childImageSharp.gatsbyImageData
                }
                alt={`Dashboard ${selectedFeature} screenshot`}
              />
            </ReactCSSTransitionGroup>

            <Block width="1088px">
              <dl>
                <Flex columns={4}>
                  {features.map(
                    ({
                      key,
                      title: featureTitle,
                      description,
                    }: DashboardFeature) => (
                      <DashboardFeatureItem
                        key={key}
                        active={selectedFeature === key}
                        role="presentation"
                        data-testid={`feat-${key}`}
                        onClick={() => setSelectedFeature(() => key)}
                      >
                        <Block margin="13px 16px 0px 16px" key={key}>
                          <div style={{ textAlign: 'initial' }}>
                            <dt>
                              <BenefitTitle fontSize={18}>
                                {featureTitle}
                              </BenefitTitle>
                            </dt>
                            <dd>
                              <BenefitText>{description}</BenefitText>
                            </dd>
                          </div>
                        </Block>
                      </DashboardFeatureItem>
                    ),
                  )}
                </Flex>
              </dl>
            </Block>
          </DashboardFeaturesContainer>
          <DashboardFeaturesContainerMobile>
            <dl>
              {features.map(
                ({ key, title: featureTitle, description }, index) => (
                  <div
                    key={index}
                    role="presentation"
                    data-testid={`feat-${key}-mobile`}
                  >
                    <DashboardFeatureScreenshot
                      image={
                        assets.find(file => file.name === key).childImageSharp
                          .gatsbyImageData
                      }
                      alt={`Dashboard ${selectedFeature} screenshot`}
                    />
                    <dt>
                      <BenefitTitle fontSize={18}>{featureTitle}</BenefitTitle>
                    </dt>
                    <dd>
                      <BenefitText>{description}</BenefitText>
                    </dd>
                  </div>
                ),
              )}
            </dl>
          </DashboardFeaturesContainerMobile>
        </Block>
        <Block margin="48px 0 46px 0">
          <FeatureCTA
            id={id}
            as="a"
            href="https://dashboard.fidel.uk/sign-up"
            rel="noopener noreferrer"
            target="_blank"
            variant="primary"
          >
            Get a test account <ArrowIcon />
          </FeatureCTA>
        </Block>
      </Flex>
    </Section>
  );
}

const assetsQuery = graphql`
  {
    selectTransactionAssets: allFile(
      filter: {
        relativeDirectory: {
          eq: "products/dashboard-features/select-transactions"
        }
      }
    ) {
      edges {
        node {
          id
          name
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;
