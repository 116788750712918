import React, { ReactNode } from 'react';
import { OverlayTrigger } from '../../../../components';
import { CTAContainer, FeatureCTA } from '../../../../components/styled';
import ArrowIcon from '../../../../static/icons/arrow.svg';
import {
  FeatureDescription,
  FeatureItem,
  FeaturesList,
  FeatureTitle,
  HeroContainer,
  HeroImage,
  ProductDescription,
  ProductTitle,
} from '../styled';

export type HeroFeature = {
  icon: ReactNode;
  title: string;
  description: string;
};

type HeroSectionProps = {
  id: string;
  title: string;
  subtitle: string;
  images: {
    desktop: string;
    mobile: string;
  };
  features: HeroFeature[];
};

export default function HeroSection({
  id,
  title,
  subtitle,
  images,
  features,
}: HeroSectionProps) {
  const { desktop, mobile } = images;
  return (
    <HeroContainer>
      <div>
        <ProductTitle>{title}</ProductTitle>
        <ProductDescription>{subtitle}</ProductDescription>

        <CTAContainer>
          <FeatureCTA
            id={`dashboardSignUp-${id}-hero`}
            as="a"
            href="https://dashboard.fidel.uk/sign-up"
            rel="noopener noreferrer"
            target="_blank"
            variant="white"
            fontWeight={700}
          >
            Get API key <ArrowIcon />
          </FeatureCTA>
          <FeatureCTA
            id={`contactSales-${id}-hero`}
            as={OverlayTrigger}
            target="contact_sales"
            variant="border"
            fontWeight={700}
          >
            Get in touch
          </FeatureCTA>
        </CTAContainer>
      </div>

      <FeaturesList>
        {features.map((feature, featureId) => (
          <FeatureItem key={featureId}>
            {feature.icon}
            <FeatureTitle>{feature.title}</FeatureTitle>
            <FeatureDescription>{feature.description}</FeatureDescription>
          </FeatureItem>
        ))}
      </FeaturesList>
      <HeroImage desktopBg={desktop} mobileBg={mobile} />
    </HeroContainer>
  );
}
