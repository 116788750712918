import styled from 'styled-components';

import { Block, FinePrint } from '../../../components/styled';
import { Select } from '../../../components';
import { breakpoints, colours } from '../../../components/styled/variables';

export const LogosContainer = styled(Block)`
  margin: 50px auto;

  @media (min-width: ${breakpoints.tablet}) {
    margin: 120px auto 90px;
  }
`;

export const BenefitTitle = styled.h3<{ fontSize?: number }>`
  font-weight: 900;
  margin: 14px 0 8px;
  font-size: ${({ fontSize }) => `${fontSize || 20}px`};
`;

export const BenefitText = styled.p`
  margin-top: 0;
  font-size: 14px;

  @media (min-width: ${breakpoints.tablet}) {
    font-size: 16px;
  }
`;

export const BenefitIcon = styled.div`
  height: 24px;

  svg {
    width: 24px;
    height: 24px;
  }

  @media (min-width: ${breakpoints.tablet}) {
    height: 32px;

    svg {
      width: 32px;
      height: 32px;
    }
  }
`;

export const Elevated = styled.div`
  position: absolute !important;
  top: 0;
  z-index: 1;
  width: 100%;
  cursor: pointer;

  .gatsby-image-wrapper {
    border-radius: 19px;
    width: 100%;
    height: 100%;

    div {
      max-width: 100% !important;
    }
  }
`;

export const VideoWrapper = styled(Block)`
  box-shadow: rgb(17 17 17 / 38%) 0px 24px 82px -22px;
  max-width: 100%;
  width: 50%;
  position: relative;
  padding-top: 28.125%;
  height: 0;
  max-height: 370px;
  display: inline-block;
  overflow: hidden;
  border-radius: 19px;

  @media (max-width: ${breakpoints.tablet}) {
    margin-bottom: 50px;
    width: 100%;
    padding-top: 56.25%;
    margin: 48px 0;
  }
`;

export const VideoEmbed = styled.iframe`
  border-radius: 19px;
  max-width: 100%;
  width: 100%;
  position: absolute;
  top: 0;
`;

export const PlayBar = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  width: 100%;
  position: absolute;
  bottom: 20px;
  z-index: 2;
  align-items: baseline;
`;

export const VideoDuration = styled.span`
  font-weight: bold;
  font-size: 12px;

  @media (max-width: ${breakpoints.tablet}) {
    font-size: 10px;
  }
`;

export const PlayButton = styled.div<{ inView: boolean }>`
  display: block;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: ${colours.primary};
  border: none;
  position: relative;
  cursor: pointer;
  ${({ inView }) => inView && `animation: pulse 900ms 2;`}

  &:after {
    content: '';
    position: absolute;
    top: 12px;
    left: 14px;
    background: none;
    transition: none;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 16px solid white;
  }
  &:hover {
    filter: brightness(0.94) contrast(1.1);
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 5px rgb(63, 127, 192, 0.2);
    }

    100% {
      box-shadow: 0 0 0 50px rgb(63, 127, 192, 0.02);
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    width: 32px;
    height: 32px;

    &:after {
      top: 9px;
      left: 12px;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      border-left: 13px solid white;
    }
  }
`;

export const ExampleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: flex-end;
  width: 100%;

  svg:first-child {
    max-width: 524px;
    max-height: 436px;
  }

  @media (max-width: ${breakpoints.tablet}) {
    justify-content: start;
    align-items: center;
    margin-top: 32px;
  }
`;

const getColor = ({ dark = true }) => colours[!dark ? 'black' : 'white'];

export const ApiSelect = styled(Select)<{ dark?: boolean }>`
  &.rc-select {
    display: block;
    align-items: center;
    width: 200px;

    @media (min-width: ${breakpoints.tablet}) {
      margin-left: 6px;
    }

    .rc-select-selector:hover + .rc-select-arrow {
      color: white;
    }

    .rc-select-selector {
      border: none;
      border-radius: 32px;
      font-size: 14px;
      color: ${getColor};
      font-weight: 500;
      padding: 4px 16px 4px 14px;
      background: ${({ dark }) =>
        !dark ? colours.lightGrey3 : colours.darkGrey3};

      :hover {
        color: white;
        .rc-select-arrow {
          color: white;
        }
      }
    }
    .rc-select-selection-item,
    .rc-select-selection-placeholder {
      margin-right: 20px;
    }
    .rc-select-arrow {
      right: 14px;
      top: 22%;
      color: ${getColor};
    }
  }
`;

ApiSelect.Option = Select.Option;

export const DevelopersList = styled.ul`
  padding: 0;
  list-style-type: none;
  color: ${colours.midGrey2};
  margin: 40px auto;
`;

export const FineprintStyled = styled(FinePrint)`
  display: block;
  color: ${colours.midGrey5};
  margin-top: 20px;
  font-size: 14px;

  a {
    color: ${colours.midGrey5};
    border-bottom: 1px ${colours.midGrey5} solid;
    font-weight: 700;

    :after {
      display: none;
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    margin-top: 40px;
  }
`;
