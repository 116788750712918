import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { Block, Section } from '../../../../components/styled';
import { breakpoints, colours } from '../../../../components/styled/variables';

export const HeroImage = styled.div<{
  desktopBg: string;
  mobileBg: string;
}>`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 65%, transparent),
    url(${props => props.desktopBg});
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 0;

  transition: transform 15s ease;

  @media (max-width: ${breakpoints.tabletPortrait}) {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 65%, transparent),
      url(${props => props.mobileBg});
    background-size: 100%;
    background-repeat: no-repeat;
  }
`;

export const HeroContainer = styled(Section)`
  max-width: 1248px;
  padding: 20px;
  position: relative;
  overflow: hidden;
  background-color: ${colours.black};
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  border-radius: 0;
  color: ${colours.white};

  &:first-child {
    margin-top: 0;
  }

  &:hover {
    ${HeroImage} {
      transform: scale(1);
    }
  }

  div:first-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px auto 20px;
    z-index: 1;
  }

  @media (min-width: ${breakpoints.tablet}) {
    border-radius: 16px;

    div:first-child {
      margin: 200px auto 120px;
    }

    &:hover {
      ${HeroImage} {
        transform: scale(1.4);
      }
    }
  }
`;

export const ProductTitle = styled.h1`
  font-size: 48px;
  margin: 0;
  padding: 0;
  text-align: center;
  font-weight: 900;

  @media (max-width: ${breakpoints.tabletPortrait}) {
    font-size: 36px;

    span {
      display: block;
    }
  }
`;

export const ProductDescription = styled.p`
  color: ${colours.white};
  font-size: 18px;
  max-width: 604px;
  text-align: center;

  margin-bottom: 40px;
  padding: 0;

  @media (max-width: ${breakpoints.tabletPortrait}) {
    font-size: 16px;
  }
`;

export const FeaturesList = styled.ul`
  display: flex;
  padding: 0px;
  z-index: 1;

  @media (max-width: ${breakpoints.tabletPortrait}) {
    flex-flow: column nowrap;
    position: initial;
  }
`;

export const FeatureItem = styled.li`
  list-style: none;
  margin: 0 40px;
  color: ${colours.white};
  width: 100%;

  @media (max-width: ${breakpoints.tabletPortrait}) {
    margin: 30px 0;
  }
`;

export const FeatureTitle = styled.h3`
  font-size: 20px;
  font-weight: 900;
  margin: 0;
  margin-bottom: 10px;

  @media (max-width: ${breakpoints.tabletPortrait}) {
    font-size: 18px;
  }
`;

export const FeatureDescription = styled.p`
  color: ${colours.white};
  opacity: 1;
  font-size: 16px;
  margin: 0;
`;

export const DashboardFeatureHeader = styled(Block)`
  align-self: center;
  width: 312px;
  margin-top: 90px;

  @media (min-width: ${breakpoints.tablet}) {
    margin-top: 0;
  }

  @media (min-width: ${breakpoints.tabletPortrait}) {
    width: 604px;
  }
`;

export const DashboardFeatureItem = styled.div<{ active: boolean }>`
  width: 270px;

  ${({ active }) =>
    active
      ? `
        background: ${colours.lightGrey5};
        border-top: none;
        border-radius: 9px;      
      `
      : `
        :hover,
        :focus {
          border-radius: 9px;
          background: ${colours.lightGrey5};
          cursor: pointer;
        }
      `}
`;

export const DashboardFeatureScreenshot = styled(GatsbyImage)`
  margin: 64px 0 32px;
  border-radius: 9px;
  border: 2px solid ${colours.lightGrey5};
  box-shadow: 0px 20px 77px 0px ${colours.shadow};

  @media (min-width: ${breakpoints.tablet}) {
    width: calc(100% - 40px);
    max-width: 100%;
    max-height: calc(((100vw - 100px) * 0.7) * 0.8);
    margin: 64px auto 32px auto;
  }
  @media (min-width: ${breakpoints.desktop}) {
    width: 1088px;
    margin: 64px 0 57px;
  }
`;

export const DashboardFeaturesContainer = styled.div`
  display: none;

  @media (min-width: ${breakpoints.tablet}) {
    display: initial;
  }
`;

export const DashboardFeaturesContainerMobile = styled.div`
  display: inline-block;

  dl {
    text-align: left;
  }

  @media (min-width: ${breakpoints.tablet}) {
    display: none;
  }
`;
