import React from 'react';

import { Block, Flex, Heading, Section } from './styled';
import { SectionProps } from './styled/section';
import { colours } from './styled/variables';

interface SideBySideSectionProps {
  content: React.ReactElement;
  heading: string;
  inverted?: boolean;
  sideContent: React.ReactElement;
  sideContentPosition?: 'left' | 'right';
  flexAlignItems?: React.CSSProperties['alignItems'];
  surtitle: string;
}

const SideBySideSection = ({
  content,
  heading,
  inverted,
  sideContent,
  sideContentPosition = 'right',
  flexAlignItems,
  surtitle,
  maxWidth,
  ...sectionProps
}: SideBySideSectionProps & SectionProps) => (
  <Section maxWidth={maxWidth || 1248} {...sectionProps}>
    <Flex
      columns={2}
      justifyContent="space-evenly"
      flow={sideContentPosition === 'left' ? 'row-reverse' : undefined}
      alignItems={flexAlignItems || 'center'}
    >
      <Block maxWidth="330px">
        <h4 style={{ color: colours.midGrey5 }}>{surtitle}</h4>
        <Heading
          size={48}
          weight={900}
          lineHeight="60px"
          color={inverted ? 'white' : 'black'}
        >
          {heading}
        </Heading>
        {content}
      </Block>

      {sideContent}
    </Flex>
  </Section>
);

export default SideBySideSection;
